import React, { useState, useContext } from 'react';
import { Box, Grid, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast } from "react-toastify";
import { serviceProvider } from '../../provider/serviceProvider';
import UserContext, { initAuthenticationTrue } from '../../context/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonComponent from '../common/ButtonComponent';
import OTPInput from '../OTPInput';

const useStyles = makeStyles({
  heading: {
    color: "#4A4A4A",
    fontFamily: "Microsoft Sans Serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  text: {
    color: "#808080",
    fontFamily: "Microsoft Sans Serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
  }
})

const VerifyOtp = ({ VerifyOtpModal, handleCloseVerifyOtpModal, mobileNumber }: any) => {

  const [otp, setOtp] = React.useState('');
  const [loader, setLoader] = useState(false);
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleResendOtp = (mobileNo: string) => {
    serviceProvider.userService
        .requestOtp(mobileNo)
        .then((res: any) => {
            toast.success(res.message);
        })
        .catch((err) => {
            toast.error(err.data?.message || 'Something Went Wrong!');
        });
};

const handleVerifyOtp = () => {
  setLoader(true);
  const payload = {mobileNumber,otp}

  // isValidRequest().then(x => {
  serviceProvider.authenticationService.login(payload).then((x:any) => {

    context.setAuthentication(initAuthenticationTrue);
      console.log({x});
      
      if (x) {
        
        handleCloseVerifyOtpModal();
        //handleCloseModal();
        console.log({x})
        if (x?.session?.role[0]?.authority &&
          ["ROLE_ADMIN:EDIT", "ROLE_ADMIN:VIEW", "ROLE_OPERATOR:EDIT", "ROLE_OPERATOR:VIEW","ROLE_BLOGGER:EDIT", "ROLE_BLOGGER:VIEW", "ROLE_MARKETER:EDIT", "ROLE_MARKETER:VIEW"].includes(x.session.role[0].authority)) {
          toast.success("User Verified.");
          window.location.href = "/";
          // rest of your code
      } else {
          toast.error("You are not authorized.");
      }
      //   if(x?.session?.role[0]?.authority === ("ROLE_ADMIN:EDIT" || "ROLE_ADMIN:VIEW" || "ROLE_OPERATOR:EDIT" || "ROLE_OPERATOR:VIEW"     )){
      //     toast.success("User Verified.")
      //     window.location.href="/";
      //     // const authorityJson = JSON.parse(JSON.stringify(x?.session?.role[0]));

      //     // navigate("/")
      //   // const isOperator:any = authorityJson?.authority === "ROLE_OPERATOR:EDIT" ? true :false;
      //   // if(isOperator){
      //   //   navigate("/operator-my-aircrafts")
         
      //   // }else{
      //   //   navigate("/users-management")
      //   // }
      // }else{
      //   toast.error("You are not authorized.");
      // }

      }else{
        toast.error(x?.message ||"Something Went Wrong.")
      }
      setLoader(false);
    
  }).catch(err => {
    console.log(err)
    setLoader(false);
    toast.error(err?.error?.data?.message || "Something Went Wrong!")
  });
};

  const classes = useStyles();

  function showLastThreeDigits(mobileNumber: string) {

    if (mobileNumber.length === 10) {
      const lastThreeDigits = mobileNumber.substring(mobileNumber.length - 3);
      const hiddenPart = 'x'.repeat(mobileNumber.length - 3);
      const maskedNumber = hiddenPart + lastThreeDigits;
      return maskedNumber;
    }
  }

  const handleChange = (newValue: any) => {
    setOtp(newValue)
  }

  return (
    <Modal open={VerifyOtpModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="modal-container verify-otp-modal">
        <Grid item xs={12}  >
        </Grid>
        <Grid item xs={12} className="verify-otp-container">
          <Grid container>
            <Grid item xs={12}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6" textAlign="center" className="heading">
                    Enter verification OTP
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" component="p" textAlign="center" className="sub-heading">
                    An OTP has been sent to {showLastThreeDigits(mobileNumber)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{textAlign:"center"}}>
                  <OTPInput
                    autoFocus
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" component="span" textAlign="center" className='sub-heading resend-otp' sx={{cursor:"pointer"}} onClick={()=>handleResendOtp(mobileNumber)}>
                    Resend OTP
                  </Typography>
                </Grid>
                <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                  <ButtonComponent loader={loader} disabled={loader || otp.length <= 5} onClick={handleVerifyOtp} label="Verify" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>

  )
}
export default VerifyOtp;

