import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  Box,
  Button,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import editIcon from "../../assets/icons/edit-icon.svg";
import { toast } from "react-toastify";
import { serviceProvider } from "../../provider/serviceProvider";
import dayjs, { Dayjs } from "dayjs";
import DateTimePickerComponent from "../common/DateTimePickerComponent";
import InputFieldComponent from "../common/InputFieldComponent";
import SelectFieldComponent from "../common/SelectFieldComponent";
import ImageUploadComponent from "../common/ImageUploadComponent";
import ButtonComponent from "../common/ButtonComponent";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { ValidationUtiltiy } from "../../utility/ValidationUtility";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/icons/back-arrow.svg";
import addImageIcon from "../../assets/icons/add-image.svg";
import AutoCompleteComponent from "../common/AutoCompleteComponent";
import { IAircraft } from "../../interface/IAircraft";
import { IAirport } from "../../interface/IAirport";
import SpinLoader from "../loader/SpinLoader";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: "inherit", // Text color
    width: "100%",
    backgroundColor: "transparent", // Remove background
    fontSize: "16px", // Set font size
    padding: "0", // Set padding
    "&:focus": {
      outline: "none", // Remove outline on focus
    },
  },
}));


const initialFlightInput = {
  active: true,
  aircraftId: "",
  description: "",
  duration: "",
  endAirportId: "",
  endDt: "",
  endMonth: "",
  flightId: "",
  flightType: "EMPTYLEG",
  headline: "",
  imageUrlList: "",
  offerList: "",
  oldPrice: 0,
  passengerCapacity: 0,
  price: 0,
  startAirportId: "",
  startDt: "",
  startMonth: "",
  showOnLandingPage:"true",
};

const AddEmptyLegFlight = () => {
  const [userInput, setUserInput] = useState(initialFlightInput);
  // const [images, setImages] = useState([] as any);
  // const [imageURLS, setImageURLs] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [startDateTime, setStartDateTime] = useState<any>(null);
//   const [arrivalDateTime, setArrivalDateTime] = useState<Dayjs | null>(null);
  const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  const [airports, setAirports] = useState([]);
  const [aircrafts, setAircrafts] = useState([]);
  const [assignedAircrafts, setAssignedAircrafts] = useState<any>([]);
  // const [files, setFiles] = useState([]);

  const [file, setFile] = useState<any>(null);
  //   const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

  const classes = useStyles();

  const handleChooseFile = () => {
    chooseFileRef.current?.click();
  };

  const navigate = useNavigate();

  const handleStartDateTime = (newDate: any) => {
    setStartDateTime(newDate);
  };
//   const handleArrivalDateTime = (newDate:any) => {
//     setArrivalDateTime(newDate);
//   };

  const emptyInputFields = () => {
    setUserInput(initialFlightInput);
    // setImageURLs([]);
    // setImages([])
  };

  console.log(userInput);
  const handleInputChange = (property: any, newValue: any) => {
    // const { name, value } = event.target;
    // setUserInput({ ...userInput, [name]: value, imageUrlList: JSON.stringify(imageURLS) })
    setUserInput({ ...userInput, [property]: newValue });
  };

  // const handleChooseFile = () => {
  //     chooseFileRef.current?.click();
  // };

  // const handleFileChange = (event: any) => {
  //     setImages([...event.target.files]);
  // };

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const isValid = ValidationUtiltiy.isValidImg(fileObj.name);
    if (isValid) {
      setFile(fileObj);
      let newFileName =
        // userDetails?.uid +
        "123" + "_" + event.target.files[0].name.replace(/\..+$/, "");
      uploadImage(fileObj, newFileName);
    } else {
      toast.error("Not a valid File");
      // handleCloseM()
    }
  };

  const uploadImage = (file: any, newFile: any) => {
    setLoader(true)
    // handleCloseM()
    const ReactS3Client = new S3(awsConfig);
    ReactS3Client.uploadFile(
      file,
      awsConfig.emptyLegFlightsSubFolder + "/" + newFile
    )
      .then((data) => {
        setLoader(false)
        // console.log("upload cover image", data);
        if (data.status === 204) {
          updateUserInput(data.location);
          toast.success("Image Uploaded");
        } else {
          // setLoader(false)
          console.log("File Not Uploaded !!");
          toast.error("Error Occured");
        }
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false)
        toast.error("Something Went Wrong.");
      });
  };
  //   console.log(imageURLS)

  const updateUserInput = (imageUrl: string) => {
    if (imageUrl !== "") setUserInput({ ...userInput, imageUrlList: imageUrl });
  };

  // useEffect(() => {
  //     if (images.length < 1) return;
  //     const newImageUrls: any = [];
  //     images.forEach((image: any) => newImageUrls.push(URL.createObjectURL(image)));
  //     setImageURLs(newImageUrls);
  // }, [images]);

  const handleAddOfferedFlight = () => {
    // const formattedDate = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
    const startDt = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
    // const endDt = arrivalDateTime ? dayjs(new Date(arrivalDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
    // const startDt =
    //   startDateTime != null
    //     ? startDateTime.toISOString()
    //     : new Date().toISOString();
    // const endDt =
    //   arrivalDateTime != null
    //     ? arrivalDateTime.toISOString()
    //     : new Date().toISOString();
    // const payload = { ...userInput, startDt, endDt, imageUrlList: JSON.stringify(imageURLS) }

    const showOnLandingPage = userInput?.showOnLandingPage === "true" ? true : false;
    const payload = { ...userInput, startDt,showOnLandingPage };
    setLoader(true);
    serviceProvider.flightService
      .post(payload)
      .then((res) => {
        // console.log({ res })
        setLoader(false);
        if (res.responseCode === 200) {
          toast.success(res.message);
          window.location.href="/empty-leg-management";
          // emptyInputFields()
          // setCabinSpecs()
        }else{
            toast.error(res?.message || "Something Went Wrong !!")
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message || "Something Went Wrong !!");
        setLoader(false);
        //   emptyInputFields()
        console.log({ err });
      });
  };

  const getAllAirportsList = () => {
    setLoader(true);
    serviceProvider.airportService
      .getAllAirports()
      .then((res) => {
        // console.log({res})
        if (res.responseCode === 200) {
          setAirports(res.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false);
      });
  };

  const getAssignedAircrafts = (operatorId: any) => {
        setLoader(true);
        serviceProvider.aircraftService
          .getAllAssingedAircrafts("NA", true,"NA")
          .then((res: any) => {
            if (res.responseCode === 200) {
              setAssignedAircrafts(res.data);
            } else {
              toast.error(res?.message || "Something Went Wrong!!");
            }
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
          });
      };

  const getAllAircraftsList = () => {
    setLoader(true);
    serviceProvider.aircraftService
      .getAllAircrafts("NA")
      .then((res) => {
        // console.log({res})
        setLoader(false);
        if (res.responseCode === 200) {
          setAircrafts(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log({ err });
      });
  };

  useEffect(() => {
    getAllAirportsList();
    getAllAircraftsList();
    getAssignedAircrafts("all");
  }, []);

  if (loader) {
    return <SpinLoader />;
  }


  return (
    <Grid container className="single-aircraft-container">
      <Grid item xs={12}>
        <Grid container mb="10px">
          <Grid item xs={6}>
            <Box className="back-btn-container">
              <Tooltip title="back">
                <IconButton onClick={() => navigate(-1)}>
                  <img src={backArrow} alt="back" />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" component="h6">
                Add Empty Leg
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={6}>
    <Box className="edit-heading-container">

 
<Typography variant="h6" component="h6" textAlign="right">Edit Details</Typography>
 <Button>
     <img src={editIcon} alt="edit icon" width="20px" />
 </Button>

</Box>
    </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} className="aircraft-image-container">
          <Grid item xs={3}>
            <Card className="image-card">
            <div className="img-box">
              <img
                src={
                  userInput.imageUrlList ? userInput.imageUrlList : addImageIcon
                }
                className={`${userInput.imageUrlList ? "card-img" : "add-img-icon"}`}
                alt="icon"
                onClick={handleChooseFile}
              />
              </div>
              <input
                style={{ display: "none" }}
                ref={chooseFileRef}
                type="file"
                onChange={handleFileChange}
              />
              {/* <img src={image} alt="img" width={"100%"} /> */}
            </Card>
          </Grid>

          <Grid item xs={3}>
            {/* <ImageUploadComponent
                            chooseFileRef={chooseFileRef}
                            handleChooseFile={handleChooseFile}
                            handleFileChange={handleFileChange}
                            isMultiple={true}
                        /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Headline
          </Typography>
          <InputFieldComponent
            isMultiline={true}
            minRows={1}
            name="headline"
            value={userInput.headline}
            onChange={(e: any) => handleInputChange("headline", e.target.value)}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Description
          </Typography>
          <InputFieldComponent
            isMultiline={true}
            minRows={4}
            name="description"
            value={userInput.description}
            onChange={(e: any) =>
              handleInputChange("description", e.target.value)
            }
          />
        </Card>
      </Grid>
      
                <Grid item xs={12}>
                <Card className="aircraft-description-card" >
                <Typography
              variant="body1"
              component="p"
              className="description-heading"
            >
              Show on landing page popup
            </Typography>
                  <FormControl fullWidth>
                    <Select
                      className={classes.input}
                      sx={{ padding: "0 10px" }}
                      value={userInput?.showOnLandingPage}
                      name="showOnLandingPage"
                      variant="standard"
                      disableUnderline
                      onChange={(e) => handleInputChange("showOnLandingPage", e.target.value)}
                    >
           
       <MenuItem value={"true"}>Yes</MenuItem>
       <MenuItem value={"false"}>No</MenuItem>
  


                      
                     
                    </Select>
                  </FormControl>
                  </Card>
                </Grid> 
              
      <Grid item xs={12}>
        <Grid container className="form">
          <Grid item xs={12} className="modal-table-container">
            <Grid container className="heading">
              <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                  Flight Details
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Aircraft
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                {/* <SelectFieldComponent
                                    name="aircraftId"
                                    value={userInput.aircraftId}
                                    options={aircrafts}
                                    onChange={(e:any) => handleInputChange(e, e.target.value)}
                                    labelKey="name"
                                    optionKey="aircraftId"
                                /> */}

                <AutoCompleteComponent
                  options={assignedAircrafts}
                  position="end"
                  // getOptionLabel={(option: any) => `${option.name} (${option.icaoCode})`}
                  getOptionLabel={(option: any) => `${option.name} (${option?.callSign})`}
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      {/* <Typography textAlign="left">{option.city}</Typography> */}
                      <Typography textAlign="left">{option.name} ({option?.callSign})</Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("aircraftId", newValue?.aircraftId)
                  }
                  placeholder="Select Aircraft"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Start Airport
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item" textAlign={"left"}>
                {/* <SelectFieldComponent
                  name="startAirportId"
                  value={userInput.startAirportId}
                  options={airports}
                  onChange={(e: any) => handleInputChange(e, e.target.value)}
                  labelKey="name"
                  optionKey="airportId"
                /> */}

                <AutoCompleteComponent
                  options={airports}
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"flex-start",
                        alignItems:"flex-start",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  position="end"
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("startAirportId", newValue?.airportId)
                  }
                  placeholder="Select Start Airport"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="destination-icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  End Airport
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <AutoCompleteComponent
                  options={airports}
                  position="end"
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"flex-start",
                        alignItems:"flex-start",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("endAirportId", newValue?.airportId)
                  }
                  placeholder="Select End Airport"
                  // isIconVisible={true}
                >
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Start date and time
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <DateTimePickerComponent
                  value={startDateTime}
                  onChange={handleStartDateTime}
                />
              </Grid>
            </Grid>
            {/* <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Arrival date and time
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                <DateTimePickerComponent
                                    value={arrivalDateTime}
                                    onChange={handleArrivalDateTime}
                                />
                            </Grid>
                        </Grid> */}
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Passenger Capacity
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="passengerCapacity"
                  type="number"
                  value={userInput.passengerCapacity}
                  onChange={(e: any) => handleInputChange("passengerCapacity", e.target.value)}
                />
              </Grid>
            </Grid>
            {/* <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Duration
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="duration"
                  type="number"
                  value={userInput.duration}
                  onChange={(e: any) => handleInputChange("duration", e.target.value)}
                />
              </Grid>
            </Grid> */}
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Price (excluding gst)
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="price"
                  type="number"
                  value={userInput.price}
                  onChange={(e: any) => handleInputChange("price", e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Old Price (excluding gst)
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="oldPrice"
                  type="number"
                  value={userInput.oldPrice}
                  onChange={(e: any) => handleInputChange("oldPrice", e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="btn-container">
        {/* variant, className, onClick, label  */}
        <ButtonComponent
          fullWidth={false}
          variant="contained"
          className="rounded-btn-fill"
          label="Save"
          onClick={handleAddOfferedFlight}
        />
      </Grid>
    </Grid>
  );
};

export default AddEmptyLegFlight;
