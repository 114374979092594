import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { a11yProps, CustomTabPanel } from '../../components/Tab/CustomTabPanel';
import ListQuotation from './ListQuotation';
import GenerateQuotation from './GenerateQuotation/GenerateQuotation';

const QuotationTabs = ({ value, handleChange }: any) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="quotation tabs">
        <Tab label="List Quotations" {...a11yProps(0)} />
        <Tab label="Generate Quotations" {...a11yProps(1)} />
        </Tabs>
      </Box>
      
      <CustomTabPanel value={value} index={0}>
        <ListQuotation />
      </CustomTabPanel>
      
      <CustomTabPanel value={value} index={1}>
        <GenerateQuotation />
      </CustomTabPanel>
    </Box>
  );
};

export default QuotationTabs;
