
import React,{useState} from "react"

import { Button, Grid, Tooltip } from '@mui/material';
import blogEditIcon from "../../assets/icons/edit-blog-icon.svg";
// import userSettingIcon from "../../assets/icons/user-setting.svg"
import blogDeactivateIcon from "../../assets/icons/deactivate-blog-icon.svg";
import blogActivateIcon from "../../assets/icons/activate-blog-icon.svg";
import { useNavigate } from "react-router-dom";
import CommonModal from "../../components/modal/CommonModal";
import DeactivateBlogModalBody from "./DeactivateBlog";
import ActivateBlogModalBody from "./ActivateBlog";
import EditBlogModalBody from "./EditBlog";
import styles from "./postBlog.module.scss";
import { sessionUtility } from "../../utility/sessionUtility";

export const ManageBlog = ({ blogDetails,isActive,pageState,getBlogsList}:any) => {
    const [openDeactivateBlog, setOpenDeactivateBlog] = useState(false);
    const [openActivateBlog, setOpenActivateBlog] = useState(false);
    const handleOpenDeactivateBlogModal = () => setOpenDeactivateBlog(true)
    const handleCloseDeactivateBlogModal = () => setOpenDeactivateBlog(false);
    const [openEditBlog, setOpenEditBlog] = useState(false);
    const navigate= useNavigate()
   
    const handleOpenEditBlogModal = () => setOpenEditBlog(true)
    const handleCloseEditBlogModal = () => setOpenEditBlog(false);
    const userDetails: any = sessionUtility.getAuthentication().session;


    const handleOpenActivateBlogModal=()=> setOpenActivateBlog(true)
    const handleCloseActivateBlogModal=()=> setOpenActivateBlog(false)
    return (
       <>
        <CommonModal open={openDeactivateBlog} handleCloseModal={handleCloseDeactivateBlogModal} modalBody={<DeactivateBlogModalBody isActive={isActive} handleCloseModal={handleCloseDeactivateBlogModal}  blogDetails={blogDetails} pageState={pageState} getBlogsList={getBlogsList}/>} />
        <CommonModal open={openActivateBlog} handleCloseModal={handleCloseActivateBlogModal} modalBody={<ActivateBlogModalBody isActive={isActive} handleCloseModal={handleCloseActivateBlogModal} blogDetails={blogDetails}  pageState={pageState} getBlogsList={getBlogsList}/>} />
        <CommonModal className={styles.editModalStyle} open={openEditBlog} handleCloseModal={handleCloseEditBlogModal} modalBody={<EditBlogModalBody isActive={isActive} handleCloseModal={handleCloseEditBlogModal} blogDetails={blogDetails} pageState={pageState} getBlogsList={getBlogsList} />} />
      <Grid container columnSpacing={2}>
        <Grid item xs={4} spacing={2}>
          <Tooltip title="Update Blog">
            <Button onClick={handleOpenEditBlogModal}>
              <img src={blogEditIcon} alt="edit icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          {
              userDetails?.role[0]?.authority === "ROLE_ADMIN:EDIT" ? 

              <Tooltip title={`${isActive ? "Deactivate Blog":"Activate Blog"}`}>
              <Button onClick={isActive?handleOpenDeactivateBlogModal:handleOpenActivateBlogModal}>
                <img src={isActive ? blogDeactivateIcon : blogActivateIcon} alt="icon" height="20px" width="20px" />
              </Button>
            </Tooltip>
            :
            <></>
          }
        </Grid>
        {/* <Grid item xs={4}>
          <Tooltip title="User Settings">
            <Button onClick={()=>navigate("/")}>
              <img src={userSettingIcon} alt="setting icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid> */}
      </Grid>
       </>
    );
  };
  