import React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box } from "@mui/material";
const DataGridTable = ({pageState,setPageState, rows, columns,showToolbar=true,showCheckbox=true }:any) => {
  return (
    <Box className="data-grid-table">
      <DataGrid density="comfortable"
        // classes={{ root: classes.root }}
        // rows={rows}
        rows={rows}
        rowHeight={40}
        autoHeight
        page={pageState.page}
        pageSize={pageState.pageSize}
        columns={columns}
        // onFilterModelChange={() => { }}
        loading={pageState.loader}
        // pageSize={10}
        // pageSize={5}
        rowsPerPageOptions={[5, 10, 15]}
        rowCount={pageState.totalPages}
        onPageChange={(newPage) =>setPageState((prev:any)=>({...prev,page:newPage}))}
        onPageSizeChange={(newPageSize) =>setPageState((prev:any)=>({...prev,pageSize:newPageSize}))}
        // rowsPerPageOptions={[10]}
        components={{ Toolbar: showToolbar ? GridToolbar : null }}
        pagination
        paginationMode='server'
        checkboxSelection={showCheckbox}
        sx={{
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bolder'
          }
        }}
      />
    </Box>
  );
};

export default DataGridTable;