import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography, MenuItem, Box, FormControl, InputLabel, InputAdornment, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DateTimePickerComponent from '../../common/DateTimePickerComponent';
import styles from './GenerateQuotation.module.scss';
import AutoCompleteComponent from '../../common/AutoCompleteComponent';
import { serviceProvider } from '../../../provider/serviceProvider';
import { IAirport } from "../../../interface/IAirport";
import departureIcon from "../../../assets/icons/depature-icon.svg";
import destinationIcon from "../../../assets/icons/destination-icon.svg";
import seatIcon from "../../../assets/icons/seat.svg";
import ButtonComponent from '../../common/ButtonComponent';
import { toast } from 'react-toastify';
import dayjs, { Dayjs } from 'dayjs';
import { Mails } from '../../../pages/mails/mails';
import CloseIcon from "@mui/icons-material/Close";

const GenerateQuotation: React.FC = ({ aircraftAvailabilityDetails = undefined }: any) => {
  const [airports, setAirports] = useState([]);

  const [showQuotationActions, setShowQuotationActions] = useState(false);

 
  const currency = [
    { currencyId: "currency1", name: "INR" },
    { currencyId: "currency2", name: "USD" },
  ];
  const ValidityType = [
    { ValidityId: "validity1", name: "HOURS" },
    { ValidityId: "validity2", name: "DAYS" },
  ];

  const handleRemoveSection = (index: number) => {
    const updatedQuotationsRequest = formData.quotationsRequest.filter(
      (_, i) => i !== index
    );
  
    setFormData({ ...formData, quotationsRequest: updatedQuotationsRequest });
  };
  

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [pdfUrl, setPdfUrl] = useState("");
    const handlePostCaseStudy = () => {
    const { quotationsRequest, validityInfo } = formData;
    const newErrors: { [key: string]: string } = {}; 

    quotationsRequest.forEach((quotation, index) => {
      if (!quotation.fromAirportId) newErrors[`fromAirportId-${index}`] = `Missing Airport Name'`;
      if (!quotation.toAirportId) newErrors[`toAirportId-${index}`] = `Missing Airport ID`;
      if (!quotation.startDt) newErrors[`startDt-${index}`] = `Missing Start Date`;
      if (!quotation.endDt) newErrors[`endDt-${index}`] = `Missing End Date `;
      if (!quotation.passengerCount) newErrors[`passengerCount-${index}`] = `Missing Passenger Count`;
      
      quotation.aircraftAndCurrencyAndAmount.forEach((aircraft, aIndex) => {
        if (!aircraft.aircraftId) newErrors[`aircraftId-${index}-${aIndex}`] = `Missing Aircraft Name`;
        if (!aircraft.amount) newErrors[`amount-${index}-${aIndex}`] = `Missing Amount `;
        if (!aircraft.currency) newErrors[`currency-${index}-${aIndex}`] = `Missing Currency`;
      });
    });

    if (!validityInfo.validityNumber) newErrors["validityNumber"] = "Missing Hours/Days of Validity";
    if (!validityInfo.validityType) newErrors["validityType"] = "Missing Hours/Days";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; 
    }

    setErrors({}); 

  
      const payload = formData;
      
      

      serviceProvider.InstantQuotationService
        .post(payload)
        .then((res: any) => {
          if (res?.responseCode === 200) {
            toast.success(res.message);

            setFormData({
              quotationsRequest: [
                {
                  aircraftAndCurrencyAndAmount: [
                    { aircraftId: "", aircraftName: "", amount: "", currency: "" }
                  ],
                  endDt: null,
                  fromAirportId: "",
                  handler: "",
                  passengerCount: "",
                  startDt: null,
                  toAirportId: ""
                }
              ],
              validityInfo: { validityNumber: "", validityType: "" }
            });
             setPdfUrl(res.data.pdfS3Url);
             console.log("res.pdfS3Url"+res.pdfS3Url)
          } else {
            toast.error(res.message);

          }
        })
        .catch((err) => {
          toast.error(err.message);

        })
        .finally(() => {
          setShowQuotationActions(true);


          
        });
    }
  const getAllAirportsList = () => {
    serviceProvider.airportService.getAllAirports().then((res) => {
      if (res.responseCode === 200) {
        setAirports(res.data)

      }

    }).catch((err) => {
  
    })
  }
 
  
  const [fleetAircrafts, setFleetAircrafts] = useState<any[]>([]);
  const getAllFleetAircrafts = () => {
    const param = `aircrafts?aircraftId=NA&fleetId=NA&minNumberOfPassengers=0&customUrl=NA`;
    serviceProvider.aircraftService
      .get(param)
      .then((res:any) => {
        if (res.responseCode === 200) {
          setFleetAircrafts(res.data);
          

        }
      })
      .catch((err:any) => {
      });
  };

  useEffect(() => {
    getAllAirportsList();    
   getAllFleetAircrafts();
  }, []);


  const [formData, setFormData] = useState({
    quotationsRequest: [
      {
        aircraftAndCurrencyAndAmount: [
          { aircraftId: "", aircraftName: "", amount: "", currency: "" }
        ],
        endDt: null,
        fromAirportId: "",
        handler: "",
        passengerCount: "",
        startDt: null,
        toAirportId: ""
      }
    ],
    validityInfo: { validityNumber: "", validityType: "" }
  });


  const handleQuotationsRequestChange = (index, field, value) => {
    const updatedQuotationsRequest = [...formData.quotationsRequest];
    if (field === 'startDt' || field === 'endDt') {
      updatedQuotationsRequest[index][field] = value 
          ? dayjs(value.$d || value).format('YYYY-MM-DDTHH:mm:ss')  
          : null;
  } else {
      updatedQuotationsRequest[index][field] = value;
  }
    setFormData({ ...formData, quotationsRequest: updatedQuotationsRequest });
  };

  const handleAircraftAndCurrencyAndAmountChange = (quotationIndex, aircraftIndex, field, value) => {
    const updatedQuotationsRequest = [...formData.quotationsRequest];
    updatedQuotationsRequest[quotationIndex].aircraftAndCurrencyAndAmount[aircraftIndex][field] = value;
    setFormData({ ...formData, quotationsRequest: updatedQuotationsRequest });
  };

  const addQuotationRequest = () => {
    setFormData({
      ...formData,
      quotationsRequest: [
        ...formData.quotationsRequest,
        {
          aircraftAndCurrencyAndAmount: [
            { aircraftId: "", aircraftName: "", amount: "", currency: "" }
          ],
          endDt: null,
          fromAirportId: "",
          handler: "",
          passengerCount: "",
          startDt: null,
          toAirportId: ""
        }
      ]
    });
  };


  const addAircraftAndCurrencyAndAmount = (index) => {
    const updatedQuotationsRequest = [...formData.quotationsRequest];
    updatedQuotationsRequest[index].aircraftAndCurrencyAndAmount.push({
      aircraftId: "",
      aircraftName: "",
      amount: "",
      currency: ""
    });
    setFormData({ ...formData, quotationsRequest: updatedQuotationsRequest });
  };

  const handleValidityInfoChange = (field, value) => {
    setFormData({
      ...formData,
      validityInfo: { ...formData.validityInfo, [field]: value }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form Data Submitted:", formData);
    setShowQuotationActions(true);

  };
  const [startDateTime, setStartDateTime] = useState<Dayjs | any>(null);

  const handleStartDateTime = (newDate: Dayjs | null) => {
    setStartDateTime(newDate);
}

const handleDownloadClick = () => {
  if (pdfUrl) {
    window.open(pdfUrl, '_blank');
  } else {
    toast.error("PDF not available. Please submit the form to generate a quotation.");
  }
};
const removeAircraftAndCurrencyAndAmount = (quotationIndex: number, aircraftIndex: number) => {
  const updatedQuotationsRequest = formData.quotationsRequest.map((quotation, index) =>
    index === quotationIndex
      ? {
          ...quotation,
          aircraftAndCurrencyAndAmount: quotation.aircraftAndCurrencyAndAmount.filter(
            (_, aIndex) => aIndex !== aircraftIndex
          ),
        }
      : quotation
  );

  setFormData({ ...formData, quotationsRequest: updatedQuotationsRequest });
};
const [open ,setOpen]=useState(false);
const handleOpenModal = () => {
  setOpen(true);
};

const handleCloseModal =() => {
  setOpen(false);
};
  return (

    
    <Box className={styles.container} >
      
      {formData.quotationsRequest.map((quotation, qIndex) => (
      <Grid container spacing={2} mb={4} key={qIndex} className={styles.ItenaryContainer}>
        <Grid item xs={12} md={6} >
        <Grid container className={styles.depatureArrivalAirportBox}>
          <Grid item xs={12}  className={styles.border}>

            <AutoCompleteComponent
              id={`fromAirportId-${qIndex}`}
              options={airports}
              value={quotation.fromAirportId}
              getOptionLabel={(option: IAirport) =>
                `${option.name} (${option.icaoCode}) - ${option.city}`
              }
              renderOption={(props: any, option: any) => (
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  {...props}
                >
                  <Typography textAlign="left">{option.city}</Typography>
                  <Typography textAlign="left">
                    {option.name} ({option.icaoCode})
                  </Typography>
                </Box>
              )}
              onChange={(event, selectedOption) => {
                if (selectedOption) {
                  handleQuotationsRequestChange(qIndex, "fromAirportId", selectedOption.airportId);
                }
              }}

              placeholder="From"
            >
              <img
                src={departureIcon}
                alt="departure-icon"
                height="25px"
                width="25px"
              />
            </AutoCompleteComponent>

          </Grid>

          
        </Grid>
        {errors[`fromAirportId-${qIndex}`] && <p className="modalError">{errors[`fromAirportId-${qIndex}`]}</p>}

      </Grid>

        <Grid item xs={12} md={6} >
        <Grid container className={styles.depatureArrivalAirportBox}>
        <Grid item xs={12}  className={styles.border}>
            <AutoCompleteComponent
              id={`toAirportId-${qIndex}`}
              options={airports}
              value={quotation.toAirportId}
              getOptionLabel={(option: IAirport) =>
                `${option.name} (${option.icaoCode}) - ${option.city}`
              }
              renderOption={(props: any, option: any) => (
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  {...props}
                >
                  <Typography textAlign="left">{option.city}</Typography>
                  <Typography textAlign="left">
                    {option.name} ({option.icaoCode})
                  </Typography>
                </Box>
              )}
              onChange={(event, selectedOption) => {
                if (selectedOption) {
                  handleQuotationsRequestChange(qIndex, "toAirportId", selectedOption.airportId);
                }
              }}
              placeholder="To"
            >
              <img
                src={destinationIcon}
                alt="destination-icon"
                height="25px"
                width="25px"
              />
            </AutoCompleteComponent>

          </Grid>
          </Grid>
          {errors[`toAirportId-${qIndex}`] && <p className="modalError">{errors[`toAirportId-${qIndex}`]}</p>}
          </Grid>
        

        <Grid item xs={12} sm={6} >
        <Grid container className={styles.dateInput}>

        <DateTimePickerComponent
          id={`startDt-${qIndex}`}
          placeholder="Start Date and Time"
          disablePast={true}
          value={quotation.startDt ? dayjs(quotation.startDt) : null}
          onChange={(e) => handleQuotationsRequestChange(qIndex, "startDt", e)}
        />
        </Grid>
        {errors[`startDt-${qIndex}`] && (
        <p className="modalError">{errors[`startDt-${qIndex}`]}</p>
      )}
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container className={styles.dateInput}>
        <DateTimePickerComponent
          id={`endDt-${qIndex}`}
          placeholder="End Date and Time"
          disablePast={true}
          value={quotation.endDt ? dayjs(quotation.endDt) : null}  
          onChange={(e) => handleQuotationsRequestChange(qIndex, "endDt", e)}       
           />
        </Grid>
        {errors[`endDt-${qIndex}`] && (
        <p className="modalError">{errors[`endDt-${qIndex}`]}</p>
      )}
        </Grid>

        <Grid item xs={12}>
        <Grid item xs={12} sm={6} className={styles.searchInput}>
          <TextField
          id={`passengerCount-${qIndex}`}
          className={styles.customInput}
          placeholder="Number of Passengers"
          value={quotation.passengerCount}
          size="small"
          type="number"
          fullWidth
          variant="standard"
          onChange={(e) => handleQuotationsRequestChange(qIndex, "passengerCount", e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={seatIcon}
                  alt="seat icon"
                  height="25px"
                  width="25px"
                />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
        </Grid>
        {errors[`passengerCount-${qIndex}`] && (
        <p className="modalError">{errors[`passengerCount-${qIndex}`]}</p>
      )}
        </Grid>

        {quotation.aircraftAndCurrencyAndAmount.map((aircraft, aIndex) => (
          <React.Fragment key={aIndex}>
          <Grid item xs={12} md={6}>
          <Grid container className={styles.depatureArrivalAirportBox}>
          <Grid item xs={12}  className={styles.border}>
        <AutoCompleteComponent
          id={`aircraftName-${qIndex}-${aIndex}`}
          options={fleetAircrafts}
          value={aircraft.aircraftName}
          getOptionLabel={(option) => option.name} 
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.name}
            </Box>
          )}
          onChange={(event, selectedOption) => {
            if (selectedOption) {
              handleAircraftAndCurrencyAndAmountChange(qIndex, aIndex, "aircraftName", selectedOption.name);
              handleAircraftAndCurrencyAndAmountChange(qIndex, aIndex, "aircraftId", selectedOption.aircraftId);
            }
          }}         
          placeholder="Choose an aircraft"
        >
          
        </AutoCompleteComponent>
      </Grid>
               
          </Grid>
          {errors[`aircraftId-${qIndex}-${aIndex}`] && (
            <p className="modalError">{errors[`aircraftId-${qIndex}-${aIndex}`]}</p>
          )} 
          
        </Grid>

        
            <Grid item xs={12} sm={6} md={3}>
            <Grid container className={styles.quotationInput}>
              <TextField
                id={`amount-${qIndex}-${aIndex}`}
                fullWidth
                size='small'
                type='number'
                value={aircraft.amount}
                onChange={(e) =>
                  handleAircraftAndCurrencyAndAmountChange(qIndex, aIndex, "amount", e.target.value)
                }                
                variant="standard"
                placeholder="Enter quotation amount"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            {errors[`amount-${qIndex}-${aIndex}`] && (
            <p className="modalError">{errors[`amount-${qIndex}-${aIndex}`]}</p>
          )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
            <Grid container className={styles.quotationCurrency}>
            <AutoCompleteComponent
            id={`currency-${qIndex}-${aIndex}`}
          options={currency}
          value={aircraft.currency}
          getOptionLabel={(option) => option.name} 
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.name}
            </Box>
          )}
          onChange={(event,selectedUser) =>
            handleAircraftAndCurrencyAndAmountChange(qIndex, aIndex, "currency", selectedUser.name)
          }          
          placeholder="Enter quotation currency"
        >
          
        </AutoCompleteComponent>
            </Grid>
            {errors[`currency-${qIndex}-${aIndex}`] && (
            <p className="modalError">{errors[`currency-${qIndex}-${aIndex}`]}</p>
          )}
            </Grid>
            <Grid item xs={12}  sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

            <Button
      startIcon={<RemoveIcon />}
      onClick={() => removeAircraftAndCurrencyAndAmount(qIndex, aIndex)}
    >
      Remove Aircraft
    </Button>
    </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12}  sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button  startIcon={<AddIcon />}  onClick={() => addAircraftAndCurrencyAndAmount(qIndex)} className={styles.addAircraftBtn}>
            Add Aircraft
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }} className={styles.rmvItenaryBtn}>
                      <ButtonComponent onClick={() => handleRemoveSection(qIndex)} fullWidth={false} label='- Remove Itenary' />
                       
                    </Grid>
      </Grid>
    ))}
<Grid item xs={12}  className={styles.addItenaryBtn}>

      <ButtonComponent onClick={addQuotationRequest} label='+ Add Itenary' fullWidth={false} />
        </Grid>
        <Grid container spacing={2} className={styles.validity} >
        <Grid item xs={12} sm={6} md={3}>
            <Grid container className={styles.quotationInput}>
              <TextField
                id="validityNumber"
                fullWidth
                size='small'
                type='number'
                value={formData.validityInfo.validityNumber}
                onChange={(e) => handleValidityInfoChange("validityNumber", e.target.value)}
                variant="standard"
                placeholder="Valid upto (Enter no. of Hours/days)"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            {errors.validityNumber && (
    <p className="modalError">{errors.validityNumber}</p>
  )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
            <Grid container className={styles.quotationCurrency}>
            <AutoCompleteComponent
            id="validityType"
          options={ValidityType}
          value={formData.validityInfo.validityType}
          getOptionLabel={(option) => option.name} 
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.name}
            </Box>
          )}
          onChange={(event,selectedOption) => handleValidityInfoChange("validityType", selectedOption.name)}
          placeholder="Select Hours/Days"
        >
          
        </AutoCompleteComponent>
            </Grid>
            {errors.validityType && (
    <p className="modalError">{errors.validityType}</p>
  )}
            </Grid>
            </Grid>
        <Grid item xs={12}  className={styles.addItenaryBtn}>
          <ButtonComponent onClick={handlePostCaseStudy} label='Generate Quotation' fullWidth={false} />
          </Grid>
            {showQuotationActions &&( <Grid container xs={12} className={styles.dlSndBtn} spacing={2}>
        <Grid item xs={12} sm={6} className={styles.downloadBtn}>
        <Button onClick={handleDownloadClick} disabled={!pdfUrl}>
          Download Quotation
        </Button>

          </Grid>
          <Grid item xs={12} sm={6} className={styles.sendBtn}>
          <Button onClick={handleOpenModal}>Send Quotation in email</Button>

        </Grid>
        </Grid>
        
      )}  

                  <Modal 
  open={open} 
  onClose={handleCloseModal} 
  className={styles.modalStyling}
>
<Grid container className={styles.modalContainer}>
        <Grid item xs={12}>
          <Button className={styles.modalCloseBtn} onClick={handleCloseModal}>
            <CloseIcon  />
          </Button>
        </Grid>

        <Grid item xs={12} className='modal-body'>
        <Box>
    <Mails pdfUrl={pdfUrl} />
  </Box>
        </Grid>
        
      </Grid>
  
</Modal>
    </Box>
  );
};

export default GenerateQuotation;


