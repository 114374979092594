import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { serviceProvider } from "../../provider/serviceProvider";

interface IAircraftInfo {
  aircraftId: string;
  aircraftName: string;
  currency: string;
  amount: number;
}

interface IQuotationRequest {
  fromAirportId: string;
  toAirportId: string;
  startDt: string;
  endDt: string;
  passengerCount: number;
  aircraftAndCurrencyAndAmount: IAircraftInfo[];
}

interface IQuotation {
  instantQuotationId: number;
  requestPayload: {
    quotationsRequest: IQuotationRequest[];
    validityInfo: {
      validityNumber: number;
      validityType: string;
    };
  };
  pdfS3Url: string;
  recordCreatedTs: string;
}

const ListQuotation = () => {
  const [quotations, setQuotations] = useState<IQuotation[]>([]);
  const [loading, setLoading] = useState(true);

  const getAllQuotations = () => {
    const param = `search?instantQuotationId=0&fromAirportId=NA&fromAirportName=NA&fromAirportIcaoCode=NA&fromAirportCity=NA&toAirportId=NA&toAirportName=NA&toAirportIcaoCode=NA&toAirportCity=NA&quotationStartDt=NA&quotationEndDt=NA&offset=0&limit=10&order=recordUpdatedTs.desc&profile=BASIC`;
    serviceProvider.InstantQuotationService
      .get(param)
      .then((res: any) => {
        if (res.responseCode === 200) {
          setQuotations(res.data.data); 
        }
        console.log({ res });
      })
      .catch((err: any) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllQuotations();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 150,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1, 
    },
    {
      field: "instantQuotationId",
      headerName: "Quotation ID",
      width: 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => `₹${params.value.toLocaleString()}`, 
    },
    {
      field: "recordCreatedTs",
      headerName: "Generated On",
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" color="primary">
          View
        </Button>
      ),
    },
  ];

  const rows = quotations.flatMap((quotation, index) =>
    quotation.requestPayload.quotationsRequest.map((request, subIndex) => ({
      id: `${index}-${subIndex}`, 
      serialNumber: index + 1,
      instantQuotationId: quotation.instantQuotationId,
      amount: request.aircraftAndCurrencyAndAmount[0]?.amount || 0, 
      recordCreatedTs: quotation.recordCreatedTs,
    }))
  );

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        pageSize={10}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </Box>
  );
};

export default ListQuotation;
